import { request } from '../request';

// 创建新用户
const userCreate = function (params) {
  return request({
    url: 'api/user/create',
    method: 'post',
    data: params
  })
};
// 用户列表
const userList = function (params) {
  return request({
    url: 'api/user/list',
    method: 'post',
    data: params
  })
};
// 公司列表
const companyList = function (params) {
  return request({
    url: 'api/company/list',
    method: 'post',
    data: params
  })
};
// 油田列表
const oilfieldList = function (params) {
  return request({
    url: 'api/oilfield/list',
    method: 'post',
    data: params
  })
};
// 设备列表
const deviceList = function (params) {
  return request({
    url: 'api/device/list',
    method: 'post',
    data: params
  })
};
// 用户个人基本信息
const userGet = function (params) {
  return request({
    url: 'api/user/get',
    method: 'post',
    data: params
  })
};
// 更新用户个人基本信息
const userUpdate = function (params) {
  return request({
    url: 'api/user/update',
    method: 'post',
    data: params
  })
}
const userPassword = function (params) {
  return request({
    url: 'api/user/password',
    method: 'post',
    data: params
  })
}
// 客户公司添加成员
const cpmpanyAddUser = function (params) {
  return request({
    url: 'api/company/add_user',
    method: 'post',
    data: params
  })
}
// 客户公司设置管理员
const cpmpanyAddAdmin = function (params) {
  return request({
    url: 'api/company/add_admin',
    method: 'post',
    data: params
  })
}
// 客户公司设置负责人
const companySetMaster = function (params) {
  return request({
    url: 'api/company/set_master',
    method: 'post',
    data: params
  })
}
// 客户公司设置普通员工
const cpmpanyUserFree = function (params) {
  return request({
    url: 'api/company/user_free',
    method: 'post',
    data: params
  })
}
// 油田添加成员
const oilfieldAddUser = function (params) {
  return request({
    url: 'api/oilfield/add_user',
    method: 'post',
    data: params
  })
}
// 油田添加管理员
const oilfieldAddAdmin = function (params) {
  return request({
    url: 'api/oilfield/add_admin',
    method: 'post',
    data: params
  })
}
// 油田设置负责人
const oilfieldSetMaster = function (params) {
  return request({
    url: 'api/oilfield/set_master',
    method: 'post',
    data: params
  })
}
// 油田添加操作员
const oilfieldAddOperator = function (params) {
  return request({
    url: 'api/oilfield/add_operator',
    method: 'post',
    data: params
  })
}
// 油田添加观察员
const oilfieldAddObserver = function (params) {
  return request({
    url: 'api/oilfield/add_observer',
    method: 'post',
    data: params
  })
}
// 油田设置普通员工
const oilfieldUserFree = function (params) {
  return request({
    url: 'api/oilfield/user_free',
    method: 'post',
    data: params
  })
}
// 查看指定设备的基本信息
const deviceGet = function (params) {
  return request({
    url: 'api/device/get',
    method: 'post',
    data: params
  })
}
// 设备添加成员
const deviceAddUser = function (params) {
  return request({
    url: 'api/device/add_user',
    method: 'post',
    data: params
  })
}
// 设备设置负责人
const deviceSetMaster = function (params) {
  return request({
    url: 'api/device/set_master',
    method: 'post',
    data: params
  })
}
// 设备添加操作员
const deviceAddOperator = function (params) {
  return request({
    url: 'api/device/add_operator',
    method: 'post',
    data: params
  })
}
// 设备添加观察员
const deviceAddObserver = function (params) {
  return request({
    url: 'api/device/add_observer',
    method: 'post',
    data: params
  })
}
// 设备设置普通员工
const deviceUserFree = function (params) {
  return request({
    url: 'api/device/user_free',
    method: 'post',
    data: params
  })
}
// 手机号搜索

export default {
  userCreate,
  userList,
  companyList,
  oilfieldList,
  deviceList,
  userGet,
  userUpdate,
  userPassword,
  cpmpanyAddUser,
  oilfieldAddUser,
  cpmpanyAddAdmin,
  companySetMaster,
  cpmpanyUserFree,
  oilfieldAddAdmin,
  oilfieldSetMaster,
  oilfieldAddOperator,
  oilfieldAddObserver,
  oilfieldUserFree,
  deviceGet,
  deviceAddUser,
  deviceSetMaster,
  deviceAddOperator,
  deviceAddObserver,
  deviceUserFree
}
